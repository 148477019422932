<template>
	<div>
		<div class="footer-top">
			<div class="wrapper">
				<div class="footer_text-2">
				</div>
			</div>
		</div>
		
		<footer class="footer">
			<div class="wrapper">
				<div class="footer_row">
					<div class="footer_text">18+ © Росконтент, {{ year() }}</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		methods: {
			year() {
				return moment().format('Y')
			}
		}
	}
</script>
