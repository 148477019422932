import default_auth from "./default_auth";
import tcell_auth from "./tcell_auth";
require('../../settings/index');

let exportAuth;

switch (window.projectName) {
	case "FMK":
		break;
		
	case projects.Tcell:
		exportAuth = tcell_auth;
		break;
		
	default:
		break;
}

if (window.settings.no_auth) {
	exportAuth = (to, from, next) => {
		next();
	};
}

export default exportAuth;
